import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import "./unsubscribe.scss";

export default function Unsubscribe({ data }) {
  const [unsubscribeFormValues, setUnsubscribeFormValues] = useState({
    email: "",
  });
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email } = unsubscribeFormValues;

    if (!email) {
      setMessage(
        "Please enter your email."
      );
      setIsError(true);
    } else if (!isEmailValid(email)) {
      setMessage("Please enter a valid email address!");
      setIsError(true);
    } else {
      setMessage("");
      setIsError(false);

      const formPayload = { email };

      try {
        const response = await fetch(
          "https://665ceoide4.execute-api.us-east-1.amazonaws.com/default/Alulux_Unsubscribe",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "H5NrxALdpu30a5D3bJkUj8XqCIeMMw2M86TsYmGh",
            },
            body: JSON.stringify(formPayload),
          }
        );

        const result = await response.json();
        if (result.statusCode === 200) {
          setMessage(result.body);
          setUnsubscribeFormValues({email: ""})
          setIsError(false);
        } else if (result.statusCode === 400) {
          setMessage(result.body);
          setUnsubscribeFormValues({email: ""})
          setIsError(true);
        } else {
          {
            setMessage(
              result.message || "An error occurred while unsubscribing."
            );
            setIsError(true);
          }
        }
      } catch (error) {
        setMessage(
          "There was a problem processing your request. Please try again later."
        );
        setIsError(true);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUnsubscribeFormValues({ ...unsubscribeFormValues, [name]: value });
  };

  const isEmailValid = (email) => {
    const emailRegex =
      /^(([0-9a-zA-Z])+([-._'+&]))*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  return (
    <Layout
      pageTitle={"Unsubscribe"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[2].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <div className="unsubscribe">
        <form onSubmit={handleSubmit}>
          <div className="title">
            <h2>Are you sure you want to unsubscribe?</h2>
            <p>
              Note: If you choose to unsubscribe, you will not be able to
              receive notifications about new projects.
            </p>
          </div>
          <div className="unsubscribe-form">
            <div className="contact-form__body">
              <input
                type="email" 
                name="email"
                placeholder="name@example.com"
                className={`input__email ${isError ? "border-error" : ""}`} 
                value={unsubscribeFormValues.email}
                onChange={handleChange}
              />
            </div>
            {message && (
              <span
                className={isError ? "input-error-message" : "success-text"}
              >
                {message}
              </span>
            )}
          </div>
          <button type="submit" className="btn-primary" id="unsubscribe-btn">
            Unsubscribe
          </button>
        </form>
      </div>
    </Layout>
  );
}

export const unsubscribePageData = graphql`
  query ProductsPageQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            id
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                id
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on ContentfulButtonsContainer {
                        buttonsContainer {
                          socialMediaLink
                          socialMediaTitle
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
